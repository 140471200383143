// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  envName: "uat",
  api: "http://cloudintercorpretail-uat.pe",
  apiLogin: "https://login.cloudintercorpretail-uat.pe",
  apiStockRead: "https://centralized-stock-read.cloudintercorpretail-uat.pe/api/centralized-stock-read/v1",
  apiStockManagement: "https://centralized-stock-management.cloudintercorpretail-uat.pe/api/centralized-stock-management/v1",
  apiStockReserve: "https://centralized-stock-reserve.cloudintercorpretail-uat.pe/api/centralized-stock-reserve/v1",
  apiStockJob: "https://centralized-stock-job.cloudintercorpretail-uat.pe/api/centralized-stock-job/v1",
  apiEntity: "https://entity.cloudintercorpretail-uat.pe/",
  sessionAuth: "centralized-auth",
  sessionData: "centralized-data"

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
